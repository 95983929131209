import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  // AiOutlineTwitter,
  AiFillMail,
  AiFillHeart
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              EN SAVOIR <span className="purple"> PLUS SUR MOI</span>
            </h1>
            <p className="home-about-body">
              Passioné des technologies, j'excerce dans différents domaines.
              <br />
              <br />
              Je fais du <b className="purple">développement</b> d'applications métier, d'outils d'<b className="purple">automatisation</b>,
              mais aussi de la conception et <b className="purple">intégration d'infrastructures</b> réseaux et systèmes, de <b className="purple">Cluster</b>,
              d'<b className="purple">hyperviseurs</b> de niv. 1 en <b className="purple">Data center</b> et de la <b className="purple">Cybersécurité</b>.
              <br />
              <br />
              J'occupe mon temps sur des activités de <b className="purple">DSI</b> et d'<b className="purple">infogéreur</b> pour des structures de diverses tailles.
              <br />
              <br />Je développe via différentes technos,
                <b className="purple"> Bash, JavaScript, Python, Node.js, PL/SQL</b> et <b className="purple">Go.</b>
              {/* <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  Blockchain.
                </b>
              </i> */}
              <br />
              <br />
              Ah oui ! J'allais oublier...<span style={{fontSize:'large', verticalAlign:'middle'}}>🫠</span> Sur mon temps libre j'occupe un rôle de <b className="purple">Directeur Pédagogique</b>,
              je gère des formateurs dans <b className="purple">toute la France</b> et rédige des <b className="purple">programmes pédagogiques</b> en formations supérieures.

              <br />
              <br />
              Dernier p'tit truc, je suis le fondateur d'une société d'une quinzaine de personnes, une <b className="purple">équipe formidable</b> ! de vrais amours <span className="purple" style={{fontSize:'x-large'}}><AiFillHeart /></span>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <div>
                <img src={myImg} className="img-fluid" alt="avatar" />
            </div>
            {/* <Tilt>
            </Tilt> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>ON SE CONNECTE ?</h1>
            <p>
              Envoyez moi <span className="purple">un ping</span> sur
              <br />
              <span style={{fontSize:'smaller'}}>
                (Vous allez voir, j'suis un type cool <span style={{fontSize:'large', verticalAlign:'middle'}}>🤙</span>)
              </span>
            </p>

            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/lessonsharing"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              {/* <li className="social-icons">
                <a
                  href="https://twitter.com/Soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li> */}

              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/ga%C3%ABtan-maiuri-723a79a4/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="mailto:hello@gmaiuri.io"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
